import Axios from "./AxiosClass";
import store from "../store/store";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import BearerTokenManager from "./BearerTokenManager";
import config from "../config";

/**
 * @class AxiosBuilder
 * @classdesc
 * Creates an instance of Axios with the default configuration.
 **/
export class AxiosBuilder {
    constructor(config = {}) {
        this.config = config;
    }

    /**
     * @method build
     * @return {Axios}
     * @description
     * Get Axios config object values asynchronously from fingerprintjs and Action api
     * Then creates custom Axios client
     **/
    async build() {
        // FIRST, check the status of the server. If anything else than a status 200 is returned => Maintenance.
        if(config.allowMaintenanceCheck){
            await Promise.all([this.withMaintenanceCheck()]);
            /* withMaintenanceCheck() has just stored a flag in the store for later use */
        }
        if( store.getters.getMaintenance ){
            /* Maintenance in progress */
            /* Won't get the token, so we stop here. */
            /* The app/form will detect it too and act accordingly. */
        }else{
            /* No maintenance detected, getting required token and fingerprint. */
            await Promise.all([this.withBearerToken()]);
            await Promise.all([this.withFingerprint()]);
            /* Those can return a different error when there is no maintenance. It will be displayed in a red box. */
        }
        return new Axios(this.config);
    }

    /**
     * @method withFingerprint
     * @return {Promise<string>}
     * @description
     * Get fingerprint visitorId from FingerprintJS and saves it to store
     **/
    async withFingerprint() {
        return (
            await FingerprintJS.load({
                token: "PZ2B2o9KoKgKPsIAoOjH",
                region: "eu",
            })
            )
        .get()
        .then((fingerprint) => {
            store.commit("SET_FINGERPRINT", fingerprint.visitorId);
        })
        .catch(() => {
            //axios is not available at this level
            fetch(config.apiUrl + '/fingerprint/' + config.idlead, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then((resp) => {
                return resp.json()
            })
            .then((fingerprint) => {
                store.commit("SET_FINGERPRINT", fingerprint)
            })
            .catch(err => console.log('err' + err))
        });
    }

    /**
     * @method withBearerToken
     * @return {Promise}
     * @description
     * Get Bearer token from BearerTokenManager and saves it to store
     **/
    async withBearerToken() {
        const token = await new BearerTokenManager().getNewTokenFromApi();
        this.config.customHeaders.Authorization = `Bearer ${token}`;
    }

    async withMaintenanceCheck() {
        return new Promise((resolve) => {
            fetch(config.apiUrl + '/health', {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(async (res) => {
                /* If anything else than a status 200 => Trigger the maintenance. */
                if(res.status === 200){
                    // Everything is alright
                    store.dispatch('setMaintenance', false);
                }else{
                 store.dispatch('setMaintenance', true);
             }
             resolve();
         })
        })

    }    
}
