<template>
	<button class="tolang" name="checkLanguage()" @click="switchLang(tolocale)" :class="{active:isActive}" :aria-pressed="isActive" role="button">
		{{label}}
	</button>
</template>

<!-- ================================================================================== -->

<script>

	export default {
		name:"LangButton",

		props : {
			label : {
				type: String,
				required: true
			},
			tolocale : {
				type: String,
				required: true
			},
			isActive : {
				type: Boolean,
				required: true,
				default: false
			}
		},

		methods: {
			/* CHANGING LOCALE BOTH IN I18N AND STORE */
			switchLang(locale) {

				this.$i18n.locale = locale;
				this.$store.commit('SET_LOCALE', locale);

			},
      checkLanguage(){
        let buttonName = "";
        if(this.$i18n.locale==="nl_BE"){
          buttonName = "Taal knop";
        }else{
          buttonName = "Bouton langue";
        }
        return buttonName;
      }
		}
	}

</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
